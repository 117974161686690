/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { forwardRef, useCallback, useContext } from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { PopupContent } from '@atlaskit/popup/experimental';
import { Box } from '@atlaskit/primitives';

import { SetIsOpenContext } from './flyout-menu-item-context';

const styles = cssMap({
	popupContent: {
		width: '280px',
	},
});

export type FlyoutMenuItemContentProps = {
	children: React.ReactNode;
	/**
	 * A `testId` that is applied to the container element as the `data-testid` attribute.
	 */
	containerTestId?: string;
	onClose?: () => void;
};

/**
 * __FlyoutMenuItemContent__
 *
 * The content that appears when the flyout menu is open.
 */
export const FlyoutMenuItemContent = forwardRef<HTMLDivElement, FlyoutMenuItemContentProps>(
	({ children, containerTestId, onClose }, forwardedRef) => {
		const setIsOpen = useContext(SetIsOpenContext);

		const handleClose = useCallback(() => {
			onClose && onClose();
			setIsOpen(false);
		}, [setIsOpen, onClose]);

		return (
			<PopupContent
				onClose={handleClose}
				placement="right-start"
				testId={containerTestId}
				shouldFitViewport
			>
				{() => (
					<Box ref={forwardedRef} padding="space.100" xcss={styles.popupContent}>
						{children}
					</Box>
				)}
			</PopupContent>
		);
	},
);
