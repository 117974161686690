import React, { type ReactNode, type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { StatusValue } from '../common/types';
import type { ExpProps as Props } from './main';
import {
	StatusLozenge as IssueStatusFieldReadonly,
	StatusLozengeWithDropdownIndicator as IssueStatusFieldComponent,
} from './status-lozenge';

export { IssueStatusFieldReadonly, IssueStatusFieldComponent };
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncLazyIssueStatusField = lazy<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-status-field" */ './main'),
);

export type ErrorProps = {
	value?: StatusValue;
	initialValue?: StatusValue;
};

export const AsyncIssueStatusField = (props: Props) => {
	const { initialValue, value } = props;
	const errorProps = { initialValue, value };
	return (
		<ErrorBoundary
			id="issue-field-status"
			packageName="jira-issue-field-status"
			render={() => <IssueStatusFieldComponent {...errorProps} />}
		>
			<Placeholder
				name="issue-status-field"
				fallback={<IssueStatusFieldComponent {...errorProps} />}
			>
				<AsyncLazyIssueStatusField {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};

export type IssueStatusFieldBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueStatusFieldBoundary = ({
	children,
	fallback,
	packageName,
}: IssueStatusFieldBoundaryProps) => (
	<ErrorBoundary id="issue-status-field" packageName={packageName} render={() => fallback}>
		<Placeholder name="issue-status-field-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
